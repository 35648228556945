<!-- 订单投诉处理弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单投诉处理':'订单投诉处理'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="15">
        <div class="xuanxianglist">
          <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
            <div style="display: flex;align-items: center;justify-content: center;">
              <img :src="item.img" style="width: 16px;height: 16px;margin-right: 10px;">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </el-row>

      <div style="margin-top: 15px;margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉时间：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">2023-12-14 12:30:54</span>
      </div>

      <div style="margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉原因：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">客户对救援师傅服务态度不好投诉</span>
      </div>
      <div style="margin-bottom: 10px;">
        <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">客户投诉内容：</span>
        <span style="font-size: 14px;font-weight: 400;color: #202033;">这是一条客户投诉内容这是一条客户投诉内容这是一条客户投诉内容</span>
      </div>
      <div style="margin-bottom: 10px;">
        <el-row :gutter="15">
          <el-col :span="4">
            <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">投诉处理说明：</span>
          </el-col>
          <el-col :span="20">
            <div>
              <div class="kuai" v-for="(item,index) in checkList" :key="index" :class="{kuai1:index==islist}" @click="active(item,index)">
                {{item.label}}
              </div>
            </div>

            <div style="margin-top: 10px;">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5}"
                  placeholder="请输入投诉处理说明"
                  v-model="form.textarea2">
              </el-input>
            </div>

            <div style="margin-top: 20px;">
              <el-upload
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>

            <div style="margin-top: 20px;">
              <el-upload
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-change="handleChange"
                  :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </div>

          </el-col>
        </el-row>
      </div>


    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //数据
      list:[
        {
          img: require('../../../../assets/images/home/genjindianhuaactive.png'),
          name:'拨打车主电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打订单来源客户电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打下游服务商电话'
        },
      ],
      isShow:0,

      radio:1,

      checkList:[
        {
          label:'订单发生地偏远'
        },
        {
          label:'当地没有司机'
        },
        {
          label:'夜间师傅少'
        },
        {
          label:'特殊天气运力紧张'
        },
        {
          label:'忘记跟单'
        },
        {
          label:'忘记完成回传跟进'
        },
      ],
      islist:0,

      dialogImageUrl: '',
      dialogVisible: false,

      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }, {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }]

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index) {
      console.log(item);
      console.log(index);
      this.isShow = index;
      if (item.name == '车主电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
      } else if (item.name == '拨打订单来源客户电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
      } else if (item.name == '拨打下游服务商电话') {
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }
    },

    //
    active(item,index){
      this.islist = index;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    }

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    width: 29%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}

.kuai{
  border: 1px solid #efeff2;
  background: #efeff2;
  border-radius: 6px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}
.kuai:hover{
  background: #FFF4E3;
  border: 1px solid #ffd38e;
  color: #FF9B05;
}
.kuai1{
  background: #FFF4E3;
  border: 1px solid #ffd38e;
  color: #FF9B05;
}
</style>
