<template>
  <!--投诉订单-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="订单号/案件号/客户/客户电话/客户/服务人员"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="受理人:">
                    <el-input
                      clearable
                      v-model="where.acceptor_nickname"
                      placeholder="请输入受理人"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.order_time"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="Datalist-botton" style="margin-left: 20px">-->
              <!--  <el-button class="custom-button" @click="CreatePenaltyOrder()">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-xiadan" />-->
              <!--    <span style="vertical-align: middle">下单</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">

            <div class="table_div">
              <div v-for="(item,index) in activeList" :key="index" class="table_div_kuai" :class="{table_div_kuai1:index==isShow}" @click="clickactive(item,index)">
                <el-badge :value="0" class="item">
                <span>
                  {{item.label}}
                </span>
                </el-badge>
              </div>
            </div>

            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="info" @click="chakan()">查看</el-link>
                <el-link :underline="false" type="warning" @click="handle()">处理</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--订单投诉处理弹窗组件-->
    <orderComplaintHandling-edit :data="current" :visible.sync="showEdit"/>

    <!--订单投诉处理查看弹窗组件-->
    <see-edit :data="seecurrent" :visible.sync="seeshowEdit"/>

  </div>
</template>

<script>
//引入订单投诉处理弹窗组件
import OrderComplaintHandlingEdit from './components/orderComplaintHandling-edit.vue'
//引入订单投诉处理弹窗查看组件
import SeeEdit from "./components/see-edit.vue";

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'
import Log from "echarts/src/scale/Log";
import AddReturnVisitEdit from "@/views/orderModule/pendingFollowUp/components/addReturnVisit-edit.vue";
import {getcomplaint,order_list_header} from "@/api/orderModule";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    OrderComplaintHandlingEdit,
    SeeEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "车牌号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          fixed:'left',
        },
        {
          label: "异常类型",
          prop: "facilitator_code",
          isShow: true
        },
        {
          label: "异常信息",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "生成时间",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "处理时间",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "处理结果",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "责任人",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "客户",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "订单来源",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "facilitator_name",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //选项
      isShow:0,
      activeList:[
        {
          label:'全部'
        },
        {
          label: '待处理'
        },
        {
          label: '已处理'
        }
      ],

      //订单投诉处理弹窗
      current:null,
      showEdit:false,

      //查看弹窗组件
      seecurrent:null,
      seeshowEdit:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
      // 调用获取自定义表头
      this.getHeader();
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 点击处罚按钮按钮
    CreatePenaltyOrder(){
      // 显示创建订单模块
      this.showEdit = true;
    },

      // 获取自定义表头
      getHeader() {
          order_list_header().then(res => {
              // console.log(res)
              // console.log(this.columns)
              // push表头
              res.data.forEach(item => {
                  let data = {
                      label: item.label,
                      prop: item.field,
                      isShow: true
                  }
                  this.columns.push(data)
              })

              // push操作列
              let data1 = {
                  label: "操作",
                  width: "200",
                  slot: "operationSlot",
                  isShow: true
              }
              this.columns.push(data1)

          })
      },

    // 获取列表
    getList(){
      getcomplaint(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
        // 调用获取自定义表头
        this.getHeader();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    clickactive(item,index){
      this.isShow = index;
    },

    //点击处理按钮
    handle(){
      this.showEdit = true;
    },
    //点击查看按钮
    chakan(){
      this.seeshowEdit = true;
    },


  }

}
</script>

<style lang="scss" scoped>
.table_div{
  border-bottom: 1px solid #eeeeee;;
  margin-bottom: 20px;
}
.table_div_kuai{
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #7b7b7b;
  margin: 15px;
  margin-bottom: 0;
  cursor: pointer;
  padding-bottom: 10px;
}
.table_div_kuai:hover{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
.table_div_kuai1{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
</style>
